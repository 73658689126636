import { wrapGetServerSidePropsWithSentry } from '@sentry/nextjs';

import { searchResultsPageGetServerSideProps } from '@/components/pages/SearchResultsPage/getServerSideProps';
import { SearchResultsPage } from '@/components/pages/SearchResultsPage/SearchResultsPage';

export const getServerSideProps = wrapGetServerSidePropsWithSentry(
  searchResultsPageGetServerSideProps,
  '[countryCodeOrLocale]/propertysearch'
);
export default SearchResultsPage;
